import React from "react";
import ReactDOM from "react-dom/client";
import {QueryClient, QueryClientProvider} from "react-query";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ProtectedRoute} from "./routes/ProtectedRoute";

import LoginPage from "./pages/auth/LoginPage";
import DashboardPage from "./pages/administrator/DashboardPage";
import {StoresPage} from "./pages/administrator/management/StoresPage";
import UsersPage from "./pages/administrator/management/UsersPage";
import SingleStorePage from "./pages/administrator/SingleStorePage";
import AnalysisPage from "./pages/administrator/AnalysisPage";
import ManageUserStoresPage from "./pages/administrator/ManageUserStoresPage";
import AcquisitionPage from "./pages/administrator/AcquisitionPage";
import ExcelProInfoPage from "./pages/administrator/ExcelProInfoPage";
import UserProfile from "./pages/administrator/menu/UserProfile";
import SettingsPage from "./pages/administrator/menu/SettingsPage";
import SupplierPage from "./pages/administrator/management/SupplierPage";
import LogsPage from "./pages/administrator/logsPage";
import AbcVedPage from "./pages/administrator/AbcVedPage";
import AdministratorRoute from "./routes/AdministratorRoute";
import PoslovodjaRoute from "./routes/PoslovodjaRoute";
import PoslovodjaPocetna from "./pages/poslovodja/PoslovodjaPocetna";
import PoslovodjaProizvodi from "./pages/poslovodja/PoslovodjaProizvodi";
import PoslovodjaLager from "./pages/poslovodja/PoslovodjaLager";
import PoslovodjaAcquisitionPage from "./pages/poslovodja/PoslovodjaAcquisitionPage";
import CreateUserPage from "./pages/administrator/management/CreateUserPage";
import ProductsByStorePage from "./pages/administrator/management/ProductsByStorePage";
import PoslovodjaCreatedOrdersPage from "./pages/poslovodja/PoslovodjaCreatedOrdersPage";
import LagerByStorePage from "./pages/administrator/management/LagerByStorePage";

import {LanguageProvider} from "./components/LanguageContext";
import HistoryByStorePage from "./pages/administrator/management/HistoryByStorePage";
import OrdersPage from "./pages/administrator/orders/OrdersPage";
import OrdersByStorePage from "./pages/administrator/orders/OrdersByStorePage";
import OrdersByCalculationPage from "./pages/administrator/orders/OrdersByCalculationPage";
import OrderInfoPage from "./pages/administrator/orders/OrderInfoPage";
import OrdersByCalculationPagePoslovodja from "./pages/poslovodja/orders/OrdersByCalculationPagePoslovodja";
import OrdersByStorePagePoslovodja from "./pages/poslovodja/orders/OrdersByStorePagePoslovodja";
import OrderInfoPagePoslovodja from "./pages/poslovodja/orders/OrderInfoPagePoslovodja";
import ReportsPage from "./pages/administrator/ReportsPage";

const ApplicationRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<ProtectedRoute/>}>
                    <Route path="administrator/" element={<AdministratorRoute/>}>
                        <Route path="pocetna" element={<DashboardPage/>}/>
                        <Route path="prodavnice" element={<StoresPage/>}/>

                        <Route path="prodavnica/:storeCode" element={<SingleStorePage/>}/>
                        <Route path="prodavnica/:storeCode/proizvodi" element={<ProductsByStorePage/>}/>
                        <Route path="prodavnica/:storeCode/lager" element={<LagerByStorePage/>}/>
                        <Route path="prodavnica/:storeCode/istorijat" element={<HistoryByStorePage/>}/>
                        <Route path="prodavnica/:storeId/informacije/:excelId" element={<ExcelProInfoPage/>}/>
                        <Route path="prodavnica/:storeId/informacije/:excelId/analiza" exact element={<AnalysisPage/>}/>
                        <Route path="prodavnica/:storeId/informacije/:excelId/abc-ved" exact element={<AbcVedPage/>}/>
                        <Route path="prodavnica/:storeId/informacije/:excelId/nabavka" exact
                               element={<AcquisitionPage/>}/>

                        <Route path="prodavnica/:storeCode/izvestaji" exact
                               element={<ReportsPage/>}/>

                        <Route path="prodavnica/:storeId/informacije/:excelId/narudzbine" exact
                               element={<OrdersByCalculationPage/>}/>
                        <Route path="prodavnica/:storeId/narudzbine" exact
                               element={<OrdersByStorePage/>}/>
                        <Route path="narudzbine" exact
                               element={<OrdersPage/>}/>
                        <Route path="narudzbina/:id" exact
                               element={<OrderInfoPage/>}/>

                        <Route path="dobavljaci" exact element={<SupplierPage/>}/>
                        <Route path="korisnici" exact element={<UsersPage/>}/>
                        <Route path="profil/:userId" exact element={<UserProfile/>}/>
                        <Route path="podešavanja" exact element={<SettingsPage/>}/>
                        <Route path="obavestenja" exact element={<LogsPage/>}/>

                        <Route path="korisnik/:userId" exact element={<ManageUserStoresPage/>}/>
                        <Route path="novi-korisnik" exact element={<CreateUserPage/>}/>
                    </Route>

                    <Route path="poslovodja/" element={<PoslovodjaRoute/>}>
                        <Route path="pocetna" exact element={<PoslovodjaPocetna/>}/>
                        <Route path="proizvodi/:storeCode" exact element={<PoslovodjaProizvodi/>}/>
                        <Route path="lager/:storeCode" exact element={<PoslovodjaLager/>}/>

                        <Route path="prodavnica/:storeId/:storeCode" exact element={<SingleStorePage/>}/>

                        <Route path="prodavnica/:storeId/informacije/:excelId/nabavka" exact
                               element={<PoslovodjaAcquisitionPage/>}/>

                        <Route path="narudzbine/kalkulacija/:excelId" exact
                               element={<OrdersByCalculationPagePoslovodja/>}/>
                        <Route path="narudzbine/:storeId" exact
                               element={<OrdersByStorePagePoslovodja/>}/>
                        <Route path="narudzbine" exact
                               element={<OrdersPage/>}/>
                        <Route path="narudzbina/:id" exact
                               element={<OrderInfoPagePoslovodja/>}/>

                        <Route path="narudžbenica/:calcId" exact element={<PoslovodjaCreatedOrdersPage/>}/>
                        <Route path="profil/:userId" exact element={<UserProfile/>}/>
                    </Route>
                </Route>
                <Route path="" index exact element={<LoginPage/>}/>
            </Routes>
        </BrowserRouter>
    );
};

function App() {
    const queryClient = new QueryClient();
    return (
        <QueryClientProvider client={queryClient}>
            <LanguageProvider>
                <ApplicationRoutes></ApplicationRoutes>
            </LanguageProvider>
        </QueryClientProvider>

    );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App/>);
