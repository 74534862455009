import axios from "axios";
import {BASE_PATH} from "../services/api";

const getCalculations = async (store_code) => {
    const response = axios.get(
        BASE_PATH + "/admin/get-calculations/" + store_code,
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    );
    return response;
};

const getReports = async (calculations) => {
    const ids = calculations.map(item => item.id);
    const response = axios.get(
        BASE_PATH + "/admin/get-reports/" + JSON.stringify(ids),
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    );
    return response;
}

const postCalculation = async (formData) => {
    const response = axios.post(BASE_PATH + "/admin/post-calculation", formData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
        },
    });

    return response;
};

export {getCalculations, postCalculation, getReports};
