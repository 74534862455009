import React, {useContext, useState} from 'react';
import {
    Alert,
    Autocomplete,
    Checkbox,
    Container,
    createFilterOptions,
    Grid,
    LinearProgress,
    Paper,
    TextField
} from "@mui/material";
import {useParams} from "react-router-dom";
import {LanguageContext} from "../../components/LanguageContext";
import {useQuery} from "react-query";
import {getCalculations, getReports} from "../../api/calculationApi";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {useTableLang} from "../../utils/tableLang";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const ReportsPage = () => {
    const {storeCode} = useParams();
    const {translations} = useContext(LanguageContext);
    const tableLang = useTableLang();

    const [calculation, setCalculation] = useState([]);

    const {data: calculations, isLoading: isLoadingCalculations} = useQuery({
        queryKey: ["calculations", storeCode],
        queryFn: () => getCalculations(storeCode),
    });

    const {data: reports, isLoading: loadingReports} = useQuery({
        queryKey: ["reports", calculation],
        queryFn: () => getReports(calculation),
        enabled: calculation.length > 0
    })

    const [calculationsError, setCalculationsError] = useState(false);
    const [calculationsErrorMessage, setCalculationsErrorMessage] = useState("");

    const columns = [
        {
            field: "id",
            headerName: translations.adminSingleStorePageId,
            width: 70,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "supplier_name",
            headerName: translations.poslovodjaProizvodiSupplierName,
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: translations.poslovodjaAcquisitionPageColumnProductName,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "code",
            headerName: translations.poslovodjaAcquisitionPageColumnProductCode,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "amount_to_get",
            headerName: translations.adminAcquisitionPageColumnToAcquire,
            minWidth: 100,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "hand_amount",
            headerName: translations.adminAcquisitionPageColumnManualInputAmount,
            minWidth: 100,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "difference",
            headerName: translations.difference,
            minWidth: 100,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "percentage_difference",
            headerName: translations.percentage_difference,
            minWidth: 100,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
    ];

    return (
        <Container maxWidth="xxl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Autocomplete
                            multiple
                            value={calculation}
                            filterOptions={(options, params) => {
                                const filter = createFilterOptions();
                                const filtered = filter(options, params);
                                return [{
                                    id: translations.chooseAll,
                                    s_name: "",
                                    date_time: "",
                                    all: true
                                }, ...filtered];
                            }}
                            onChange={(event, newValue) => {
                                if (newValue.find((option) => option.all))
                                    return setCalculation(
                                        calculation.length == calculations?.data.length
                                            ? []
                                            : calculations?.data,
                                    );

                                setCalculation(newValue);
                            }}
                            options={isLoadingCalculations ? [] : calculations?.data}
                            loading={isLoadingCalculations}
                            disableCloseOnSelect
                            disabled={calculations?.data == 0 || calculations?.data == undefined || calculations?.data == null || isLoadingCalculations}
                            getOptionLabel={(option) => (option.id + " " + option.s_name + " " +
                                (new Date(option.date_time).getDate()
                                    + "/" + (new Date(option.date_time).getMonth() + 1)
                                    + "/" + new Date(option.date_time).getFullYear()))}
                            renderOption={(props, option, {selected}) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{marginRight: 8}}
                                        checked={
                                            option.all
                                                ? calculation.length == calculations?.data.length
                                                : selected
                                        }
                                    />
                                    {option.id + " " + option.s_name + " "
                                        + (option.date_time != "" ? (new Date(option.date_time).getDate()
                                            + "/" + (new Date(option.date_time).getMonth() + 1)
                                            + "/" + new Date(option.date_time).getFullYear()) : "")}
                                </li>
                            )}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={translations.chooseCalculations}
                                    placeholder={translations.chooseCalculations}
                                    margin="normal"
                                    required
                                    error={calculationsError}
                                    helperText={calculationsErrorMessage}
                                />
                            )}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        {calculation.length == 0 ? (
                            <Alert severity="warning">{translations.errorMessageReports}</Alert>
                        ) : (
                            loadingReports ? <LinearProgress size={24} color="inherit"/> : (
                                <DataGrid
                                    rows={loadingReports ? [] : reports?.data}
                                    slots={{toolbar: GridToolbar}}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {page: 0, pageSize: 25},
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick
                                />
                            )

                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ReportsPage;