import React, {useContext, useState} from 'react';
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    createFilterOptions,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import {useParams} from "react-router";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {createOrder, getOrder, updateHandKolicina} from "../../api/orderApi";
import Container from "@mui/material/Container";
import UpdateButtons from "../../components/UpdateButtons";
import Grid from "@mui/material/Grid";
import AbcTwoToneIcon from "@mui/icons-material/AbcTwoTone";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import OnlinePredictionTwoToneIcon from "@mui/icons-material/OnlinePredictionTwoTone";
import Paper from "@mui/material/Paper";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {useTableLang} from "../../utils/tableLang";
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import {LanguageContext} from "../../components/LanguageContext";
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const AcquisitionPage = () => {
    const tableLang = useTableLang();
    const {translations} = useContext(LanguageContext);
    const {storeId, excelId} = useParams();
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const [suppliers, setSuppliers] = useState([]);

    const [suppliersError, setSuppliersError] = useState(false);
    const [suppliersErrorMessage, setSupliersErrorMessage] = useState("");

    const queryClient = useQueryClient();

    const [handAmounts, setHandAmounts] = useState({});
    const [editValues, setEditValues] = useState({});

    const {data, isLoading} = useQuery({
        queryKey: ["orderGet", excelId],
        queryFn: () => getOrder(excelId),
    });

    const mutation = useMutation({
        mutationFn: (formData) => updateHandKolicina(formData),
        onSuccess: (response) => {
            setSuccess(response.data.message);
            setError("");

            queryClient.invalidateQueries({queryKey: ["orderGet", excelId]}).then(r => console.log("reload"));

            setTimeout(() => {
                setSuccess("");
            }, 10000);
        },
        onError: (error) => {
            setSuccess("");
            setError(error.response.data.message);

            setTimeout(() => {
                setError("");
            }, 10000);
        }
    });

    const createOrderMutation = useMutation({
        mutationFn: (formData) => createOrder(formData),
        onSuccess: (response) => {
            setSuccess(response.data.message);
            setError("");
            setLoading(false);
            setTimeout(() => {
                setSuccess("");
            }, 10000);
        },
        onError: (error) => {
            setSuccess("");
            setError(error.response.data.message);
            setLoading(false);
            setTimeout(() => {
                setError("");
            }, 10000);
        }
    })

    const [open, setOpen] = useState(false);
    const [type, setType] = useState(0);
    const [message, setMessage] = useState("");

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleUpdate = (e, value, product_code, transport_package) => {
        const formData = new FormData();

        if (product_code == null) {
            setSuccess("");
            setError("Šifra proizvoda ne sme biti prazna!");
            return;
        }

        formData.append('value', value);
        formData.append('calc_id', excelId);
        formData.append('product_code', product_code);
        formData.append('store_code', storeId);

        mutation.mutateAsync(formData).catch((e) => {
        });
    }

    const handleKeyDown = (e, code, transport_package) => {
        if (e.key == 'Enter') {
            e.preventDefault(); // Prevent form submission if inside a form

            const value = e.target.value;

            if (value < 0) {
                setType(1);
                setMessage(translations.messageIfAmountLessThanZero);
                setOpen(true);
                return;
            }

            if (transport_package > 1) {
                if (value % transport_package == 0) {
                    handleUpdate(e, value, code, transport_package);
                    handleChange(e, code);
                    setType(0);
                    setOpen(true);
                    setMessage(<span>{translations.poslovodjaLagercode}: {code}
                        <br/> {translations.quantity} = {value}
                        <br/> TP = {transport_package}</span>);
                } else {
                    const v = Number(Number(value) + Number(transport_package - (value % transport_package))).toFixed(0);
                    handleUpdate(e, v, code, transport_package);
                    e.target.value = v;
                    handleChange(e, code);
                    setType(0);
                    setOpen(true);
                    setMessage(<span>{translations.poslovodjaLagercode}: {code}
                        <br/> {translations.messagePart1}: {value} {translations.messageTo} {v} {translations.messagePart2}
                        <br/> TP = {transport_package}</span>);
                }

            } else {
                handleUpdate(e, value, code, transport_package);
                handleChange(e, code);
                setType(0);
                setOpen(true);
                setMessage(<span>{translations.poslovodjaLagercode}: {code}
                    <br/> {translations.quantity} = {value}
                    <br/> TP = {transport_package}</span>);
            }
        }
    };

    const handleChange = (e, code) => {
        const value = e.target.value;

        setEditValues((prev) => ({
            ...prev,
            [code]: value, // Update local edit state
        }));
    };

    const handleCreateOrder = (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();

        if (suppliers.length == 0) {
            setLoading(false);
            setSuppliersError(true);
            setSupliersErrorMessage(translations.suppliersErrorMessage);
            return;
        }
        setSuppliersError(false);
        setSupliersErrorMessage("");

        formData.append('calc_id', excelId);
        formData.append('store_code', storeId);
        formData.append('suppliers', JSON.stringify(suppliers));
        formData.append('type', 1);

        createOrderMutation.mutateAsync(formData).catch((e) => {
        });
    }

    const columns = [
        {
            field: "order_id",
            headerName: translations.adminAcquisitionPageColumnID,
            minWidth: 50,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "supplier_name",
            headerName: translations.poslovodjaProizvodiSupplierName,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "code",
            headerName: translations.adminAcquisitionPageColumnProductCode,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "name",
            headerName: translations.adminAcquisitionPageColumnProductName,
            minWidth: 250,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "transport_package",
            headerName: translations.poslovodjaAcquisitionPageColumnTransportPackage,
            minWidth: 250,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => (
                <span>{parseFloat(params.row.transport_package).toFixed(0)} {translations.pieces}</span>
            ),
        },
        {
            field: "needed",
            headerName: translations.adminAcquisitionPageColumnNeeded,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => (
                <span>{parseFloat(params.row.needed).toFixed(0)}</span>
            ),
        },
        {
            field: "amount_on_storage",
            headerName: translations.adminAcquisitionPageColumnOnStock,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "amount_on_order",
            headerName: translations.onOrder,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "rop",
            headerName: translations.adminAcquisitionPageColumnOrderLevel,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => (
                <span>{parseFloat(params.row.rop).toFixed(0)}</span>
            ),
        },
        {
            field: "amount_to_get",
            headerName: translations.adminAcquisitionPageColumnToAcquire,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => (
                <span>{parseFloat(params.row.amount_to_get).toFixed(0)}</span>
            ),
        },
        {
            field: "hand_amount",
            headerName: translations.adminAcquisitionPageColumnManualInputAmount,
            align: "left",
            headerAlign: "center",
            minWidth: 250,
            flex: 2,
            renderCell: (params) => {
                const code = params.row.code; // Unique identifier for the row
                const value = handAmounts[code] || params.row.hand_amount.toFixed(0);
                const transport_package = params.row.transport_package;

                const localValue = editValues[code] ?? value;
                return (
                    <Box sx={{mt: 1, display: 'flex', flexDirection: 'row'}}>
                        <TextField
                            value={localValue}
                            size="normal"
                            id={`hand_amount_${code}`}
                            label={translations.adminAcquisitionPageLabelManualInputAmount}
                            variant="filled"
                            onChange={(e) => handleChange(e, code)}
                            onKeyDown={(e) => handleKeyDown(e, code, transport_package)}
                        />
                    </Box>
                );
            }
        },
    ];

    return (
        <Container maxWidth="xxl" sx={{mt: 4, mb: 4}}>
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                {type == 0 ? (
                    <Alert
                        onClose={handleClose}
                        severity="success"
                        variant="filled"
                        sx={{width: '100%', textAlign: 'center'}}
                    >
                        {message}
                    </Alert>
                ) : (
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        variant="filled"
                        sx={{width: '100%', textAlign: 'center'}}
                    >
                        {message}
                    </Alert>
                )}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item lg={4} sm={4} xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Button
                            variant="contained"
                            size="large"
                            href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/abc-ved`}
                            fullWidth
                        >
                            <AbcTwoToneIcon sx={{mr: 1}}/>
                            {translations.adminAcquisitionPageABC}
                        </Button>
                    </Paper>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Button
                            variant="contained"
                            size="large"
                            href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/analiza`}
                            fullWidth
                        >
                            <LeaderboardIcon sx={{mr: 1}}/>
                            {translations.adminAcquisitionPageAnaliza}
                        </Button>
                    </Paper>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Button
                            variant="contained"
                            size="large"
                            href={`/administrator/prodavnica/${storeId}/informacije/${excelId}`}
                            fullWidth
                        >
                            <OnlinePredictionTwoToneIcon sx={{mr: 1}}/>
                            {translations.adminAcquisitionPagePrognoza}
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <UpdateButtons
                            calc_id={excelId}
                            store_code={storeId}
                            success={success}
                            setSuccess={setSuccess}
                            error={error}
                            setError={setError}
                        />
                        <Alert severity="info" sx={{
                            textAlign: 'center',
                            fontSize: 25,
                            fontWeight: "bold",
                            color: "red",
                            mt: 2
                        }}>{translations?.updateOrdersAfterInventoryUpdateMessage}</Alert>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Button
                            variant="contained"
                            size="large"
                            href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/narudzbine`}
                            fullWidth
                        >
                            <FolderCopyIcon sx={{mr: 1}}/>
                            {translations.createdOrders}
                        </Button>
                    </Paper>
                </Grid>
                {success == "" ? null : (
                    <Grid item xs={12}>
                        <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                            <Alert severity="success" sx={{justifyContent: "center"}}>
                                {success}
                            </Alert>
                        </Paper>
                    </Grid>
                )}
                {error == "" ? null : (
                    <Grid item xs={12}>
                        <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                            <Alert severity="error" sx={{justifyContent: "center"}}>
                                {error}
                            </Alert>
                        </Paper>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Paper sx={{display: "flex", flexDirection: "column", height: 900}}>
                        {
                            data?.data.length == 0 || data?.data == undefined || data?.data == null ? (
                                <Alert severity="warning">{translations?.messageIfEmptyData}</Alert>
                            ) : isLoading ? (
                                <CircularProgress/>
                            ) : (
                                <DataGrid
                                    rows={isLoading ? [] : data?.data.orders}
                                    columns={columns}
                                    slots={{toolbar: GridToolbar}}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {pageSize: 25},
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick
                                    density={"comfortable"}
                                />
                            )
                        }
                        <Autocomplete
                            multiple
                            value={suppliers}
                            filterOptions={(options, params) => {
                                const filter = createFilterOptions();
                                const filtered = filter(options, params);
                                return [{supplier_name: translations.chooseAll, all: true}, ...filtered];
                            }}
                            onChange={(event, newValue) => {
                                if (newValue.find((option) => option.all))
                                    return setSuppliers(
                                        suppliers.length == data?.data.suppliers.length
                                            ? []
                                            : data?.data.suppliers,
                                    );

                                setSuppliers(newValue);
                            }}
                            options={isLoading ? [] : data?.data.suppliers}
                            loading={isLoading}
                            disableCloseOnSelect
                            disabled={data?.data.suppliers.length == 0 || data?.data.suppliers == undefined || data?.data.suppliers == null || isLoading}
                            getOptionLabel={(option) => option.supplier_name + option.supplier_code}
                            renderOption={(props, option, {selected}) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{marginRight: 8}}
                                        checked={
                                            option.all
                                                ? suppliers.length == data?.data.suppliers.length
                                                : selected
                                        }
                                    />
                                    {option.supplier_name}
                                </li>
                            )}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={translations.chooseSuppliers}
                                    placeholder={translations.chooseSuppliers}
                                    margin="normal"
                                    required
                                    error={suppliersError}
                                    helperText={suppliersErrorMessage}
                                />
                            )}
                        />

                        <Button
                            onClick={handleCreateOrder}
                            variant="contained"
                            fullWidth
                            color="secondary"
                            sx={{mt: 2}}
                            disabled={loading}
                        >
                            {loading ? (
                                <CircularProgress
                                    size={32}
                                    color="primary"
                                    thickness={5}
                                    sx={{mr: 2}}
                                />
                            ) : (
                                <ListAltTwoToneIcon sx={{mr: 1}}/>
                            )}
                            <Typography sx={{fontWeight: "bold", fontSize: 15}}>
                                {translations.adminCreateOrder}
                            </Typography>
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AcquisitionPage;