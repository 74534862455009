import {BASE_PATH} from "../services/api";
import axios from "axios";

const loginApi = async (formData) => {
    const response = await axios.post(BASE_PATH + "/login", formData, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return response;
};

const logoutApi = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

    const formData = new FormData();
    formData.append("id", localStorage.getItem("user_id"));

    const response = await fetch(BASE_PATH + "/logout", {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "manual",
    });
    const language = localStorage.getItem("language");
    localStorage.clear();
    if (language) {
        localStorage.setItem("language", language);
    }
    return response.json();
};

export {loginApi, logoutApi};
